<template>
  <div>
    <b-card no-body>
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <b-form-group
              :label="$t('labels.channel_id')"
              label-for="channel_id"
            >
              <b-form-input
                id="channel_id"
                :value="searchFields.channel_id"
                :placeholder="$t('labels.channel_id')"
                @input="inputChange($event, 'channel_id')"
              />
            </b-form-group>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mb-2"
          >
            <label>{{ $t('labels.status') }}</label>
            <v-select
              v-model="searchFields.status"
              :dir="$store.state.appConfig.layout.direction"
              :options="statuses"
              :reduce="item => item.id"
              label="name"
              class="w-100"
              :placeholder="$t('labels.status')"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <TableIndex
      :resource-name="resourceName"
      :singular-name="singularName"
      :filter-fields="searchFields"
      :custom-change-status="true"
      :custom-update-object="customUpdateObject"
    >
      <template v-slot:status_action="{ item }">
        <div class="position-relative">
          <b-form-checkbox
            v-model="item.status"
            checked="true"
            value="enabled"
            unchecked-value="disabled"
            name="check-button"
            switch
          />
          <div
            class="switch-layer"
            @click.prevent="changeStatus($event, item)"
          />
        </div>
      </template>
    </TableIndex>

    <!-- modal -->
    <b-modal
      id="change-status-modal"
      ref="change-status-modal"
      modal-class="modal-primary"
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ $t('messages.are_you_sure_to_change_status') }}
      </b-card-text>
      <template #modal-footer="{ cancel }">
        <b-button @click="cancel()">
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant="primary"
          @click="confirmChangeStatus"
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import listMixin from '@/mixins/listMixin'

export default {
  mixins: [listMixin],
  data() {
    return {
      resourceName: 'youtube-channels',
      singularName: 'youtube-channel',
      searchFields: {
        status: null,
        channel_id: null,
      },
      selectedItem: null,
      customUpdateObject: null,
    }
  },
  methods: {
    // eslint-disable-next-line func-names
    inputChange: _.debounce(function (e, field) {
      this.searchFields[field] = e
    }, 500),
    changeStatus($e, item) {
      this.$refs['change-status-modal'].show()
      this.selectedItem = item
    },
    confirmChangeStatus() {
      this.$refs['change-status-modal'].hide()
      const $item = this.selectedItem
      const $link = `/youtube-channels/change-status/${$item.id}`
      this.axios
        .patch($link, { status: $item.status === 'enabled' ? 'disabled' : 'enabled' })
        .then(res => {
          this.$bvToast.toast(res.data.message, {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })

          this.customUpdateObject = {
            id: $item.id,
            status: $item.status === 'enabled' ? 'disabled' : 'enabled',
            status_value: $item.status === 'enabled' ? this.$t('statuses.enabled') : this.$t('statuses.disabled'),
          }
          this.selectedItem = null
        })
        .catch(error => {
          this.$bvToast.toast(error.response.data.message, {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
  },
  computed: {
    statuses() {
      return [
        {
          id: 'enabled',
          name: this.$t('statuses.enabled'),
        },
        {
          id: 'disabled',
          name: this.$t('statuses.disabled'),
        },
      ]
    },
  },
}
</script>
